export interface NigerianStates {
    name: string;
    capital: string;
    state_code: string;
    creation_date: string;
    location: Location;
    total_area: string | null;
    population: number | null;
    postal_code?: any;
    religions: any[];
  }
  
  interface Location {
    latitude: string;
    longitude: string;
  }
  
  export const NIGERIAN_STATES: NigerianStates[] = [
    {
      name: 'Abia State',
      capital: 'Umuahia',
      state_code: 'ABIA',
      creation_date: '1991',
      location: {
        latitude: '5.4527',
        longitude: '7.5248',
      },
      total_area: '6320',
      population: 4112230,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Adamawa',
      capital: 'Yola',
      state_code: 'ADAMAWA',
      creation_date: '1991',
      location: {
        latitude: '9.3265',
        longitude: '12.3984',
      },
      total_area: '36917',
      population: 3178950,
      postal_code: 640001,
      religions: [],
    },
    {
      name: 'Akwa Ibom',
      capital: 'Uyo',
      state_code: 'AKWA',
      creation_date: '1987',
      location: {
        latitude: '4.9057',
        longitude: '7.8537',
      },
      total_area: '7081',
      population: 5450758,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Anambra',
      capital: 'Awka',
      state_code: 'ANAMBRA',
      creation_date: '1991',
      location: {
        latitude: '6.2209',
        longitude: '6.9370',
      },
      total_area: '4844',
      population: 4177821,
      postal_code: 420001,
      religions: [],
    },
    {
      name: 'Bauchi',
      capital: 'Bauchi',
      state_code: 'BAUCHI',
      creation_date: '1976',
      location: {
        latitude: '10.7761',
        longitude: '9.9992',
      },
      total_area: '45893',
      population: 4676465,
      postal_code: 740001,
      religions: [],
    },
    {
      name: 'Bayelsa',
      capital: 'Yenagoa',
      state_code: 'BAYELSA',
      creation_date: '1996',
      location: {
        latitude: '4.9247',
        longitude: '6.2662',
      },
      total_area: '10773',
      population: 2400000,
      postal_code: 562101,
      religions: [],
    },
    {
      name: 'Benue',
      capital: 'Makurdi',
      state_code: 'BENUE',
      creation_date: '1976',
      location: {
        latitude: '7.7348',
        longitude: '8.5401',
      },
      total_area: '34059',
      population: 5800000,
      postal_code: 972108,
      religions: [],
    },
    {
      name: 'Borno',
      capital: 'Maiduguri',
      state_code: 'BORNO',
      creation_date: '1976',
      location: {
        latitude: '11.8469',
        longitude: '13.1606',
      },
      total_area: '70898',
      population: 5860000,
      postal_code: 600001,
      religions: [],
    },
    {
      name: 'Cross River',
      capital: 'Calabar',
      state_code: 'CROSSRIVER',
      creation_date: '1967',
      location: {
        latitude: '4.9534',
        longitude: '8.3290',
      },
      total_area: '20156',
      population: 3700000,
      postal_code: 550101,
      religions: [],
    },
    {
      name: 'Delta',
      capital: 'Asaba',
      state_code: 'DELTA',
      creation_date: '1991',
      location: {
        latitude: '6.2024',
        longitude: '7.0029',
      },
      total_area: '17698',
      population: 4100000,
      postal_code: 320242,
      religions: [],
    },
    {
      name: 'Ebonyi',
      capital: 'Abakaliki',
      state_code: 'EBONYI',
      creation_date: '1996',
      location: {
        latitude: '6.3161',
        longitude: '8.1165',
      },
      total_area: '5533',
      population: 2800000,
      postal_code: 480101,
      religions: [],
    },
    {
      name: 'Edo',
      capital: 'Benin City',
      state_code: 'EDO',
      creation_date: '1991',
      location: {
        latitude: '6.3405',
        longitude: '5.6214',
      },
      total_area: '17802',
      population: 4200000,
      postal_code: 300001,
      religions: [],
    },
    {
      name: 'Ekiti',
      capital: 'Ado Ekiti',
      state_code: 'EKITI',
      creation_date: '1996',
      location: {
        latitude: '7.6237',
        longitude: '5.2202',
      },
      total_area: '6353',
      population: 3300000,
      postal_code: 360101,
      religions: [],
    },
    {
      name: 'Enugu',
      capital: 'Enugu',
      state_code: 'ENUGU',
      creation_date: '1991',
      location: {
        latitude: '6.5244',
        longitude: '7.5103',
      },
      total_area: '7161',
      population: 4800000,
      postal_code: 400102,
      religions: [],
    },
    {
      name: 'Gombe',
      capital: 'Gombe',
      state_code: 'GOMBE',
      creation_date: '1996',
      location: {
        latitude: '10.2897',
        longitude: '11.1673',
      },
      total_area: '20265',
      population: 3400000,
      postal_code: 760242,
      religions: [],
    },
    {
      name: 'Imo',
      capital: 'Owerri',
      state_code: 'IMO',
      creation_date: '1976',
      location: {
        latitude: '5.4836',
        longitude: '7.0260',
      },
      total_area: '5530',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Jigawa',
      capital: 'Dutse',
      state_code: 'JIGAWA',
      creation_date: '1991',
      location: {
        latitude: '12.1740',
        longitude: '9.5678',
      },
      total_area: '23154',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Kaduna',
      capital: 'Kaduna',
      state_code: 'KADUNA',
      creation_date: '1976',
      location: {
        latitude: '10.5239',
        longitude: '7.4406',
      },
      total_area: '46053',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Kano',
      capital: 'Kano',
      state_code: 'KANO',
      creation_date: '1967',
      location: {
        latitude: '12.0022',
        longitude: '8.59195',
      },
      total_area: '20131',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Katsina',
      capital: 'Katsina',
      state_code: 'KATSINA',
      creation_date: '1987',
      location: {
        latitude: '12.9894',
        longitude: '\t7.6000',
      },
      total_area: '24192',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Kebbi',
      capital: 'Birnin Kebbi',
      state_code: 'KEBBI',
      creation_date: '1991',
      location: {
        latitude: '12.4534',
        longitude: '4.1975',
      },
      total_area: '36800',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Kogi',
      capital: 'Lokoja',
      state_code: 'KOGI',
      creation_date: '1991',
      location: {
        latitude: '7.8000',
        longitude: '6.7333',
      },
      total_area: '29833',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Kwara',
      capital: 'Ilorin',
      state_code: 'KWARA',
      creation_date: '1967',
      location: {
        latitude: '8.5000',
        longitude: '5.7500',
      },
      total_area: '36825',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Lagos',
      capital: 'Ikeja',
      state_code: 'LAGOS',
      creation_date: '1967',
      location: {
        latitude: '6.5244',
        longitude: '3.3792',
      },
      total_area: '3345',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Nasarawa',
      capital: 'Lafia',
      state_code: 'NASARAWA',
      creation_date: '1996',
      location: {
        latitude: '8.4967',
        longitude: '8.5150',
      },
      total_area: '27117',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Niger',
      capital: 'Minna',
      state_code: 'NIGER',
      creation_date: '1976',
      location: {
        latitude: '9.6134',
        longitude: '6.5561',
      },
      total_area: '76363',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Ogun',
      capital: 'Abeokuta',
      state_code: 'OGUN',
      creation_date: '1976',
      location: {
        latitude: '7.1558',
        longitude: '3.3458',
      },
      total_area: '16762',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Ondo',
      capital: 'Akure',
      state_code: 'ONDO',
      creation_date: '1976',
      location: {
        latitude: '7.2500',
        longitude: '5.1950',
      },
      total_area: '15500',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Osun',
      capital: 'Osogbo',
      state_code: 'OSUN',
      creation_date: '1991',
      location: {
        latitude: '7.7669',
        longitude: '4.5567',
      },
      total_area: '9251',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Oyo',
      capital: 'Ibadan',
      state_code: 'OYO',
      creation_date: '1976',
      location: {
        latitude: '7.3775',
        longitude: '3.9470',
      },
      total_area: null,
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Plateau',
      capital: 'Jos',
      state_code: 'PLATEAU',
      creation_date: '1976',
      location: {
        latitude: '9.9150',
        longitude: '8.9000',
      },
      total_area: '26899',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Rivers',
      capital: 'Port Harcourt',
      state_code: 'RIVERS',
      creation_date: '1967',
      location: {
        latitude: '4.7774',
        longitude: '7.0134',
      },
      total_area: '11077',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Sokoto',
      capital: 'Sokoto',
      state_code: 'SOKOTO',
      creation_date: '1976',
      location: {
        latitude: '13.0496',
        longitude: '5.2925',
      },
      total_area: '25973',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Taraba',
      capital: 'Jalingo',
      state_code: 'TARABA',
      creation_date: '1991',
      location: {
        latitude: '8.8964',
        longitude: '11.5011',
      },
      total_area: '54473',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Yobe',
      capital: 'Damaturu',
      state_code: 'YOBE',
      creation_date: '1991',
      location: {
        latitude: '11.7480',
        longitude: '11.9600',
      },
      total_area: '45502',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Zamfara',
      capital: 'Gusau',
      state_code: 'ZAMFARA',
      creation_date: '1996',
      location: {
        latitude: '12.1628',
        longitude: '6.6654',
      },
      total_area: '39762',
      population: null,
      postal_code: null,
      religions: [],
    },
    {
      name: 'Federal Capital Territory (FCT)',
      capital: 'Abuja',
      state_code: 'FCT',
      creation_date: '1991',
      location: {
        latitude: '9.0579',
        longitude: '7.4951',
      },
      total_area: '7315',
      population: null,
      postal_code: null,
      religions: ['Muslim', 'Christian'],
    },
  ];
  